angular.module('cerberus.admin')
    .controller('WorkflowsDesignerShapeModalCtrl', function WorkflowsDesignerShapeModalCtr(_, CodeMirror, $scope, $uibModalInstance, $timeout, formIdArray,
                                                                                            stateId, states, widgetId, workflowObject, workspaceId, 
                                                                                            DesignerUtilityService, FormsService, ViewsService, WidgetsService,
                                                                                            WidgetsSettingsService, WorkflowsClassService, WorkspacesGroupsService) {
        var vm = this;

        vm.pageTitle = 'Edit Shape';
        vm.orig = angular.copy(workflowObject);
        vm.temp = angular.copy(workflowObject);
        vm.stateId = stateId;
        vm.jsonEdit = angular.copy(angular.toJson(vm.temp));
        vm.widgetId = widgetId;
        vm.formFields = [];
        vm.targetLookups = [];
        vm.formArray = [];
        vm.forms = [];
        vm.views = [];
        vm.viewColumns = [];
        vm.widgets = [];
        vm.widgetFields = [];
        vm.groups = [];
        vm.subTypes = WorkflowsClassService.getDefaultSettings('procSubTypes');
        vm.emailTemplates = WorkflowsClassService.getDefaultSettings('emailTemplates');
        vm.emailAccounts = WorkflowsClassService.getDefaultSettings('emailAccounts');
        vm.tempFieldMap = {};

        vm.targetLookupChanged = targetLookupChanged;
        vm.isLookup = isLookup;
        vm.hasWidgetId = hasWidgetId;
        vm.getWidgetName = getWidgetName;
        vm.notificationViewSelected = notificationViewSelected;
        
        vm.notificationGroupsOptions = {
            dataTextField: 'name',
            dataValueField: 'id',
            valuePrimitive: true,
            dataSource: {
                transport: {
                    read: function (options) {
                        options.success(vm.groups);
                    }
                }
            }
        };

        $scope.detailGridOptions = function (dataItem) {
            return {
                dataSource: {
                    data: dataItem.objects,
                    schema: {
                        model: {
                            fields: {
                                label: { type: 'string' },
                                modelId: { type: 'string' },
                                usage: { type: 'string' }
                            }
                        }
                    },
                    pageSize: 20
                },
                selectable: 'row',
                change: function (e) {
                    var selectedRows = e.sender.select();
                    var dataItm = e.sender.dataItem(selectedRows).usage;
                    $timeout(function () {
                        if (vm.tempFieldMap.val) {
                            vm.tempFieldMap.val = vm.tempFieldMap.val + ' ' + dataItm;
                        }
                        else {
                            vm.tempFieldMap.val = dataItm;
                        }
                    }, 0);
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                columns: [
                    { field: 'label', title: 'Field Name', width: '110px' },
                    { field: 'modelId', title: 'Unique Field Name', width: '110px' },
                    { field: 'usage', title: 'Usage', width: '110px' }
                ]
            };
        };

        vm.groupsFilter = function (group) {
            return !group.isSystem || group.type === 'User';
        };

        vm.ok = function () {
            $uibModalInstance.close(vm.temp);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.updateParams = function () {
            var params = WorkflowsClassService.getParams(vm.temp.model.subType);
            _.assign(vm.temp.model.params, params);

            if (vm.temp.model.subType === 'updateInstance') {
                targetLookupChanged(vm.temp.model.params, vm.formFields);
            }
        };

        vm.getGroupName = function (gid) {
            return _.result(_.find(vm.groups, 'id', gid), 'name');
        };

        vm.addGroup = function (pid) {
            if (!vm.temp.model.permissions[pid]) {
                vm.temp.model.permissions[pid] = {
                    create: true,
                    read: true,
                    update: true,
                    delete: true
                };
            }
        };

        vm.removeGroup = function (pid) {
            if (vm.temp.model.permissions[pid]) {
                delete vm.temp.model.permissions[pid];
            }
        };

        vm.loadWidgetFields = function (wid) {
            if (wid) {
                WidgetsSettingsService.getFormFields(wid).then(function (data) {
                    vm.widgetFields = parseFormsToFields(data.display);
                    autoSetFK();
                });
            }
            else {
                vm.widgetFields = [];
                vm.temp.model.params.fk = {};
            }
        };

        vm.addMap = function (map) {
            vm.temp.model.params.map.push({
                targetModelId: map.id,
                formula: map.val
            });
            map.id = null;
            map.val = '';
        };

        vm.removeMap = function (index) {
            vm.temp.model.params.map.splice(index, 1);
        };

        vm.FormulaHelper =  "\t..:: USEFUL VARIABLES AND FORMULA (Consult ColdFusion for help) ::..\n" +
            "'Len', 'Left', 'Mid', 'Right', 'UCase,\n" +
			"'DateDiff', 'ParseDateTime', 'DateTimeFormat', 'Now',\n" +
			"'Replace', 'REReplace', 'IsValid', 'IsDefined', 'IsNull',\n" +
            "'StructKeyExists', 'IsStruct',\n" +
            "'functions.queryView',\n" +
            "'functions.queryViewAdvRegEx',\n" +
            "'functions.createStruct',\n\n" +
            "\t...::: CONDITIONS (Check 'condition.cfc' if needed) :::...\n" +
            "AND, OR, EQ, NEQ, GT, LT, GTE, LTE, TRUE, FALSE";

        //Init function
        _init();
        ////////////////////
        /**
         * Init controller data and server calls
         */
        function _init() {
            WorkspacesGroupsService.getGroups(workspaceId).then(function (groups) {
                vm.groups = groups;
            });

            ViewsService.get(false).then(function (views) {
                vm.views = views;
            });

            if (vm.temp.model.subType == 'notification' && vm.temp.model.params.viewId) {
                loadViewColumns(vm.temp.model.params.viewId);
            }

            var formIds = angular.copy(formIdArray);
            if (workflowObject.model.type == 'document') {
                for (var fid = 0; fid < workflowObject.model.forms.length; fid++) {
                    if (formIds.indexOf(workflowObject.model.forms[fid]) == -1) {
                        formIds.push(workflowObject.model.forms[fid]);
                    }
                }
            }

            if (formIds.length > 0) {
                WidgetsSettingsService.getFormFields(widgetId, formIds).then(function (data) {
                    var fields = data.display;
                    vm.mainGridOptions = DesignerUtilityService.formGrid(fields);
                    vm.formFields = parseFormsToFields(fields);
                    vm.targetLookups = [{ modelId: '', label: '(None)', widgetId: widgetId }].concat(vm.formFields);
                });
            }

            DesignerUtilityService.focusFirstElement('configForm');
            WidgetsService.getAllForms(vm.widgetId).then(function (data) {
                for (var f = 0; f < data.length; f++) {
                    var form = data[f];
                    vm.formArray.push({
                        id: getFormId(form.originatorId),
                        name: form.name
                    });
                }
                vm.documentFormOptions = {
                    dataTextField: 'name',
                    dataValueField: 'id',
                    dataSource: vm.formArray,
                    value: vm.temp.model.forms,
                    valuePrimitive: true,
                    change: function (e) {
                        var formIds = angular.copy(formIdArray);
                        vm.temp.model.forms = e.sender.value();
                        for (var fo = 0; fo < vm.temp.model.forms.length; fo++) {
                            if (formIds.indexOf(vm.temp.model.forms[fo]) == -1) {
                                formIds.push(vm.temp.model.forms[fo]);
                            }
                        }
                        if (formIds.length > 0) {
                            WidgetsSettingsService.getFormFields(widgetId, formIds).then(function (data) {
                                vm.formFields = parseFormsToFields(data.display);
                                vm.targetLookups = [{ modelId: '', label: '(None)', widgetId: widgetId }].concat(vm.formFields);
                            });
                        }
                        else {
                            vm.formFields = [{ modelId: '', label: '(None)', widgetId: widgetId }];
                        }
                    }
                };
            });

            WidgetsService.getWidgets().then(function (widgets) {
                vm.widgets = widgets;
            });

            if (_.get(vm.temp.model, 'params.targetWidgetId')) {
                WidgetsSettingsService.getFormFields(vm.temp.model.params.targetWidgetId).then(function (data) {
                    vm.widgetFields = parseFormsToFields(data.display);
                });
            }
        }

        function getFormId(guid) {
            var ids = guid.split('-');
            return ids[ids.length - 1];
        }

        function getWidgetName(wId) {
            var widget = _.find(vm.widgets, 'id', wId);
            return _.get(widget, 'name', '');
        }

        function parseFormsToFields(forms) {
            var formFields = [];
            for (var i = 0; i < forms.length; i++) {
                formFields = formFields.concat(forms[i].objects);
            }
            return formFields;
        }

        function autoSetFK() {
            if (vm.widgetFields) {
                var lookUp = _.find(vm.widgetFields, 'widgetId', vm.widgetId);
                var fk = {};
                if (lookUp) {
                    fk = _.pick(lookUp, ['column', 'modelId', 'optionId']);
                }
                _.set(vm.temp.model, 'params.fk', fk);
            }
        }

        function targetLookupChanged(params, formFields) {
            if (params.lookupModelId) {
                var lookup = _.find(formFields, 'modelId', params.lookupModelId);
                if (lookup || lookup.widgetId) {
                    params.targetWidgetId = lookup.widgetId;
                    params.type = lookup.type;
                    if (params.type == 'dataseries') {
                        params.fk = lookup.fk;
                    }
                    WidgetsSettingsService.getFormFields(lookup.widgetId).then(function (data) {
                        vm.widgetFields = parseFormsToFields(data.display);
                    });
                }
                else {
                    vm.widgetFields = [];
                    params.targetWidgetId = '';
                }
            }
            else {
                vm.widgetFields = formFields;
                params.targetWidgetId = widgetId;
            }
        }

        function isLookup(field) {
            return field.isLookUp;
        }

        function hasWidgetId(field) {
            return !!(field.widgetId);
        }

        function notificationViewSelected(params) {
            params.pkColumn = '';
            params.staticMsgColumn1 = '';
            params.viewMsgColumn1 = '';
            params.staticMsgColumn2 = '';
            params.viewMsgColumn2 = '';
            params.staticMsgColumn3 = '';

            loadViewColumns(params.viewId);
        }

        function loadViewColumns(viewId) {
            ViewsService.getColumns(viewId).then(function (columns) {
                vm.viewColumns = columns;
            });
        }

        var timeout;
        vm.codemirrorLoaded = function (_editor) {
            // Editor part
            var _doc = _editor.getDoc();
            _editor.focus();

            // Options
            _editor.setOption('theme', 'nimcalc');
            _editor.setOption('lineWrapping', true);
            _editor.setOption('matchBrackets', true);
            _editor.setOption('autoCloseBrackets', '()[]\"\"');

            _editor.setOption('mode', {
                name: 'nimcalc',
                globalVars: []
            });
            _doc.markClean();

            // Events
            _editor.on('inputRead', function (cm) {
                if (timeout) {
                    clearTimeout(timeout);
                }
                timeout = $timeout(function () {
                    CodeMirror.showHint(cm, CodeMirror.hint.nimcalc, {
                        completeSingle: false,
                        localVars: vm.formFieldModelId
                    });
                }, 150, false);
            });
            _editor.on('keyup', function (cm, event) {
                var keyCode = event.keyCode || event.which;
                if (keyCode == 8 || keyCode == 46) {
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                    timeout = $timeout(function () {
                        CodeMirror.showHint(cm, CodeMirror.hint.nimcalc, {
                            completeSingle: false
                        });
                    }, 150, false);
                }
                vm.updateData();
            });
        };

        vm.editorOptions = function (_editor) {
            // Editor part
            var _doc = _editor.getDoc();
            // Options
            _editor.setOption('lineWrapping', true);
            _editor.setOption('lineNumbers', true);
            _editor.setOption('matchBrackets', true);
            _editor.setOption('autoCloseBrackets', true);
            _editor.setOption('mode', 'application/ld+json');
            //Prep
            _doc.markClean();
            _editor.focus();
            //Open
            _editor.setSize('100%', '500px');
            _editor.on('keyup', function (cm, event) {
                var keyCode = event.keyCode || event.which;
                if (keyCode === 192 && event.ctrlKey) {
                    var formatted_json = vm.formatJson(_editor.getValue());
                    _editor.setValue(formatted_json);
                }
                vm.updateData();
            });
        };

        function repeat(s, count) {
            return new Array(count + 1).join(s);
        }

        vm.formatJson = function (json) {
            var i = 0,
                il = 0,
                tab = '  ',
                newJson = '',
                indentLevel = 0,
                inString = false,
                currentChar = null;

            for (i = 0, il = json.length; i < il; i += 1) {
                currentChar = json.charAt(i);

                switch (currentChar) {
                    case '{':
                    case '[':
                        if (!inString) {
                            newJson += currentChar + '\n' + repeat(tab, indentLevel + 1);
                            indentLevel += 1;
                        } else {
                            newJson += currentChar;
                        }
                        break;
                    case '}':
                    case ']':
                        if (!inString) {
                            indentLevel -= 1;
                            newJson += '\n' + repeat(tab, indentLevel) + currentChar;
                        } else {
                            newJson += currentChar;
                        }
                        break;
                    case ',':
                        if (!inString) {
                            newJson += ',\n' + repeat(tab, indentLevel);
                        } else {
                            newJson += currentChar;
                        }
                        break;
                    case ':':
                        if (!inString) {
                            newJson += ': ';
                        } else {
                            newJson += currentChar;
                        }
                        break;
                    case ' ':
                    case '\n':
                    case '\t':
                        if (inString) {
                            newJson += currentChar;
                        }
                        break;
                    case '"':
                        if (i > 0 && json.charAt(i - 1) !== '\\') {
                            inString = !inString;
                        }
                        newJson += currentChar;
                        break;
                    default:
                        newJson += currentChar;
                        break;
                }
            }
            return newJson;
        };

        vm.updateData = function () {
            $timeout(function () {
                vm.temp = angular.copy(angular.fromJson(vm.jsonEdit));
                $scope.$apply();
            });
        };

        vm.updateJson = function () {
            $timeout(function () {
                vm.jsonEdit = vm.formatJson(angular.toJson(vm.temp));
                $scope.$apply();
            });
        };

        $scope.$watch('vm.temp', function () {
            vm.updateJson();
        }, true);

        $scope.$on('$locationChangeStart', function (event) {
            event.preventDefault();
        });
    })
;